.profile {
	background-color: var(--alternative-bg);
	padding: 5px 20px 5px 5px;
	border-radius: 50px;
	display: flex;
	column-gap: 12px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.profileTab {
	color: white;
	font-weight: 600;
	display: flex;
	align-items: center;
	column-gap: 10px;
}

.name {
	max-width: 185px;
	font-size: var(--font-fluid-sm);
}

.profileTab svg {
	font-size: 1.5rem;
	margin-top: 3px;
}

.profileMenu,
.profileMenuClose {
	position: absolute;
	z-index: -1;
	top: 25px;
	left: 0;
	border-radius: 0 0 20px 20px;
	padding-block: 25px 1px;
	background-color: var(--alternative-bg);
	font-size: var(--font-fluid-sm);
	width: 100%;
	max-height: 150px;
	transition: all 0.2s;
	visibility: visible;
	opacity: 1;
}

.profileMenuClose {
	max-height: 0;
	padding: 0;
	opacity: 0;
	visibility: hidden;
}

.label {
	color: white;
	font-weight: 600;
	display: flex;
	gap: 15px;
	align-items: center;
	padding: 10px 15px;
}

.label svg {
	font-size: 1.3rem;
}

.label:hover {
	color: var(--input-bg);
}
