.globalLoader {
	width: 100%;
	height: 100%;
	background-color: var(--light);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.globalLoader img {
	width: 150px;
	animation: smooothpulse 1.5s infinite;
}

/* animation logo loading */
@keyframes smooothpulse {
	0% {
		transform: scale(0.95);
		opacity: 0.7;
	}
	50% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0.95);
		opacity: 0.7;
	}
}
