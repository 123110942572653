.searchBox {
	background-color: var(--light);
	padding: 20px;
	border-radius: 4px;
	margin-top: 25px;
	margin-bottom: 20px;
	transition: all 0.5s ease;
}

.searchBox p {
	margin-block: 5px 40px;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--input-border);
	transition: all 0.5s ease;
}

.searchBar {
	display: grid;
	grid-template-columns: minmax(265px, 765px) 140px;
	column-gap: 25px;
	row-gap: 5px;
}

.searchInput {
	background-image: url('../../../../../Assets/icons/search-lens.png');
	background-repeat: no-repeat;
	background-position: 10px center;
	background-size: 20px;
	padding-inline: 30px;
}

@media screen and (max-width: 575px) {
	.searchBar {
		grid-template-columns: 1fr;
	}
}
