.sidebar,
.sidebarOpened,
.sidebarMobile {
	height: 100%;
	width: var(--width-sidebar-collapsed);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 7000;
	transition: all 0.5s ease;
	display: flex;
	flex-direction: column;
	background-color: var(--light);
	border-right: 1px solid var(--border-sidebar);
	animation: fade 0.5s ease;
}

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.sidebarOpened {
	width: var(--width-sidebar);
}

.sidebarMobile {
	width: var(--width-sidebar);
	left: calc(var(--width-sidebar) * (-1));
}

.boxAvatar {
	margin-top: 30px;
	padding: 0 35px;
	overflow: hidden;
	flex-shrink: 0;
	transition: all 0.4s;
}

.avatar {
	margin-inline: auto;
	height: 78px;
	width: 78px;
	border-radius: 50%;
	border: 2px solid var(--primary);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-bottom: 10px;
	transition: all 0.4s;
	cursor: pointer;
}

.userName {
	text-align: center;
	margin-bottom: 35px;
	font-weight: 500;
	font-size: 18px;
	color: var(--text-primary);
	transition: all 0.4s;
}

.sidebar .boxAvatar {
	padding: 0 5px;
}

.sidebar .avatar {
	height: 48px;
	width: 48px;
}
.sidebar .userName {
	visibility: hidden;
	opacity: 0;
	margin-bottom: 5px;
}

.boxNav {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex-grow: 2;
	overflow-y: auto;
	overflow: overlay;
	overflow-x: hidden;
	min-height: 100px;
	padding-inline: 25px;
	scrollbar-color: var(--primary) transparent; /* Only Firefox */
}
.boxNav::-webkit-scrollbar {
	width: 1px; /* Chrome */
	background: transparent; /* Chrome */
}

.boxNav nav {
	margin-bottom: 50px;
}
.boxNav nav svg {
	font-size: 20px;
	transform: scaleX(-1);
}

.boxNav ul li a {
	display: flex;
	align-items: center;
	width: 100%;
}
.boxNav ul .element {
	cursor: pointer;
}
.boxNav ul .element,
.boxNav ul .elementActive {
	margin-bottom: 14px;
}

.boxNav ul .element div.mainCategory {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding: 12px;
	cursor: pointer;
	color: var(--text-primary);
	border-radius: 8px;
	/* transition: all 0.3s; */
}

.mainCategory,
.mainCategoryOpenCollapsed {
	cursor: pointer;
}

.boxNav ul .element div.mainCategoryOpenCollapsed {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding: 12px;
	border-radius: 8px;
}
.mainCategory .innerCategory,
.mainCategoryOpenCollapsed .innerCategory {
	display: flex;
	align-items: center;
	gap: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.iconWrapper {
	display: flex;
	flex-shrink: 0;
	transition: all 0.3s;
}

.iconWrapper {
	padding-left: 2.4px;
}

.boxNav ul li .icon svg {
	height: 20px;
	width: 20px;
	margin-right: 14px;
	color: var(--text-primary);
}
.boxNav ul li svg.arrow,
.boxNav ul li svg.arrowActive {
	color: var(--text-primary);
	font-size: 20px;
	cursor: pointer;
	transition: all 0.3s;
	flex-shrink: 0;
}
.boxNav ul li svg.arrowActive {
	transform: rotate(180deg);
}
.boxNav ul li svg path {
	fill: var(--text-primary);
	transition: all 0.3s;
}

.sidebar .boxNav ul li svg.arrow {
	visibility: hidden;
	opacity: 0;
	display: none;
}

.boxNav ul .elementActive div.mainCategory,
.boxNav ul .elementActive div.mainCategoryOpenCollapsed {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 12px;
	border-radius: 8px;
	color: white;
	background-color: var(--primary);
	transition: all 0.3s;
}

.boxNav ul .elementActive .categoryText,
.boxNav ul .elementActive .arrow,
.boxNav ul .elementActive .mainCategoryOpenCollapsed .categoryText,
.boxNav ul .elementActive .mainCategoryOpenCollapsed .arrow {
	transition: all 0.3s;
	color: white;
}

.sidebar .boxNav ul .elementActive .categoryText,
.sidebar .boxNav ul .elementActive .arrow,
.sidebar .boxNav ul .elementActive .mainCategoryOpenCollapsed .categoryText,
.sidebar .boxNav ul .elementActive .mainCategoryOpenCollapsed .arrow {
	opacity: 0;
}

.boxNav ul .element .mainCategory:hover .categoryText,
.boxNav ul .element .mainCategory:hover .arrow,
.boxNav ul .element .mainCategoryOpenCollapsed:hover .categoryText,
.boxNav ul .element .mainCategoryOpenCollapsed:hover .arrow {
	transition: all 0.3s;
	color: var(--primary);
}

.boxNav ul .elementActive svg path,
.boxNav ul .elementActive .mainCategoryOpenCollapsed svg path {
	fill: white;
}
.boxNav ul .element .mainCategory:hover svg path,
.boxNav ul .element .mainCategoryOpenCollapsed:hover svg path {
	transition: all 0.3s;
	fill: var(--primary);
}
.boxNav ul .mainCategoryOpenCollapsed .categoryText,
.boxNav ul .mainCategoryOpenCollapsed .arrow {
	color: var(--text-primary);
}

.boxNav ul li .categoryText {
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.2px;
	color: var(--text-primary);
	opacity: 1;
	transition: all 0.3s;
	overflow: hidden;
	white-space: nowrap;
}

.boxNav div.subCategory {
	position: relative;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.2px;
	color: var(--text-primary);
}

.boxNav div.subCategory .innerSub {
	margin-left: 9px;
}
.boxNav div.subCategory .innerSub div.innerElement,
.boxNav div.subCategory .innerSub div.innerElementActive {
	padding: 12px;
	width: 100%;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.2px;
	color: var(--text-primary);
	transition: 0.3s;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.boxNav div.subCategory:hover div.innerElement,
.boxNav div.subCategory .innerSub div.innerElementActive {
	color: var(--primary);
}

ul.closeDropdown {
	opacity: 0;
	transition: all 0.3s;
	overflow: hidden;
}
.closeDropdown.active {
	opacity: 1;
	transition: all 0.3s;
	overflow: hidden;
	margin-bottom: 14px;
}

.containerBox {
	padding-inline: 25px;
	border-top: 1px solid var(--border-sidebar);
	transition: all 0.5s ease;
}
.footerSidebar {
	padding: 15px 12px 15px;
	display: flex;
	align-items: center;
	gap: 12px;
	color: var(--text-primary);
	font-weight: 400;
	font-size: 16px;
	transition: all 0.3s;
}
.footerSidebar:hover {
	cursor: pointer;
	color: var(--primary);
}

.footerSidebar svg {
	font-size: 20px;
}
.iconFooter {
	display: flex;
	flex-shrink: 0;
}
.labelFooter {
	opacity: 1;
	transition: all 0.3s;
}

.sidebar .footerSidebar .labelFooter {
	opacity: 0;
	padding-left: 2.4px;
}

.lock {
	display: flex;
	transition: all 0.3s;
}

.lock svg path {
	color: var(--text-primary) !important;
	fill: inherit !important;
}

.mainCategory:hover .lock svg path {
	color: var(--primary) !important;
}

.sidebar .lock {
	visibility: hidden;
	opacity: 0;
	display: none;
}
