.system {
	padding: 28px 40px 50px;
	background-color: var(--light);
	margin-block: 25px 20px;
	border-radius: 4px;
	transition: all 0.5s ease;
}

.box {
	display: grid;
	grid-template-columns: 185px 1fr;
	column-gap: 20px;
	row-gap: 10px;
	align-items: center;
}

.box:not(:last-child) {
	margin-bottom: 25px;
}

.label {
	font-weight: 600;
	margin-bottom: 20px;
}

.values {
	border: 1px solid var(--input-border);
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 11px;
	color: var(--text-primary-opacity);
	font-weight: 500;
}

.create {
	margin-top: 35px;
	max-width: 140px;
	margin-left: auto;
}

@media screen and (max-width: 767px) {
	.system {
		padding: 28px 20px 50px;
	}
	.box {
		grid-template-columns: 1fr;
	}
	.box:not(:last-child) {
		margin-bottom: 20px;
	}
	.label {
		margin-bottom: 0;
	}
}
