.actions {
	background-color: var(--light);
	padding: 20px;
	border-radius: 4px;
	margin-top: 25px;
	margin-bottom: 20px;
	transition: all 0.5s ease;
}

.export {
	transition: all 0.5s ease;
	padding-bottom: 20px;
	column-gap: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.actions p {
	margin-top: 5px;
}

.exportButton {
	gap: 10px;
	padding-inline: 25px !important;
}

.exportButton svg {
	font-size: 1.1rem;
	flex-shrink: 0;
}

.filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 25px;
	row-gap: 5px;
}

.dateBox {
	display: flex;
	gap: 10px;
}

.filterButton {
	padding-inline: 20px !important;
	white-space: nowrap;
}

@media screen and (max-width: 767px) {
	.export {
		display: block;
	}
	.exportButton {
		margin-top: 10px;
		max-width: 200px;
		margin-left: auto;
	}
	.filter {
		display: block;
	}
	.dateBox {
		margin-top: 10px;
		justify-content: space-between;
	}
}

@media screen and (max-width: 575px) {
	.dateBox {
		display: block;
	}
	.filterButton {
		margin-top: 10px;
	}
	.exportButton {
		max-width: 100%;
	}
}
