.datePicker {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	font-size: 14px;
	column-gap: 10px;
	margin-right: 10px;
	border: 1px solid var(--input-border);
	border-radius: 5px;
	padding: 10px 15px;
	transition: all 0.5s ease;
}
.datePicker svg {
	color: var(--text-secondary);
	font-size: 23px;
	cursor: pointer;
	transition: all 0.5s ease;
}
.datePicker:hover,
.datePicker:hover svg {
	color: var(--primary);
	cursor: pointer;
}

.datePicker:hover .textSelect {
	color: var(--primary);
	transition: all 0.5s ease;
}

.filterDate {
	display: flex;
	align-items: center;
}

.clearDate {
	display: flex;
	align-items: center;
	column-gap: 3px;
}
.clearDate svg {
	color: var(--text-secondary);
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.5s ease;
}
.clearDate:hover {
	color: var(--primary);
	cursor: pointer;
	transition: all 0.5s ease;
}

.textSelect,
.clearDate .textClear {
	font-weight: 500;
	font-size: 14px;
	color: var(--text-secondary);
	cursor: pointer;
	white-space: nowrap;
	transition: all 0.5s ease;
}
.textSelect:hover,
.clearDate:hover .textClear {
	color: var(--primary);
}

.textClear {
	display: flex;
}
.textClear svg {
	font-size: 20px;
	color: var(--text-secondary);
	transition: all 0.5s ease;
}
.textClear svg:hover {
	color: var(--primary);
	transition: all 0.5s ease;
}
