.navbarContainer {
	width: 100%;
	height: var(--height-navbar);
	background-color: var(--light);
	transition: all var(--transition-theme);
	box-shadow: 0px 4px 40px 1px rgba(0, 0, 0, 0.03);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-inline: 30px;
	position: relative;
	z-index: 8000;
	animation: fade 0.5s ease;
}

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.leftSide {
	display: flex;
	align-items: center;
	gap: 20px;
}
.bar {
	height: 35px;
	background-color: var(--input-border);
	width: 1px;
	transition: all 0.5s ease;
}

.rightSide {
	display: flex;
	align-items: center;
	gap: 25px;
}

.menuButton,
.open {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.menuButton:hover,
.open:hover {
	cursor: pointer;
}

.topBar {
	width: 22px;
	height: 3px;
	background-color: var(--text-primary);
	transition: all 0.5s ease;
}

.middleBar {
	width: 18px;
	height: 2px;
	background-color: var(--text-primary);
	transition: all 0.5s ease;
}

.bottomBar {
	width: 24px;
	height: 3px;
	transition: all 0.5s ease;
	background-color: var(--text-primary);
}

.open .topBar {
	transform: rotate(45deg) translate(5px, 5.5px);
}

.open .middleBar {
	opacity: 0;
}

.open .bottomBar {
	transform: rotate(-45deg) translate(5px, -5.5px);
	width: 22px;
}

.menuButton:hover .arrowOpen,
.menuButton:hover .arrowClose {
	color: var(--primary);
}

@media screen and (max-width: 575px) {
	.navbarContainer {
		padding-inline: 10px;
	}

	.leftSide,
	.rightSide {
		gap: 15px;
	}
}

/* _______________________________ */
.list {
	cursor: pointer;
	top: 90px;
	left: 50%;
	transform: translate(-50%, 0);
	position: fixed;
	font-weight: 800;
}

.openTwo {
	background-color: white;
	box-shadow: 0px 4px 40px 1px rgba(0, 0, 0, 0.1);
	padding: 10px;
	width: 100%;
	border-radius: 5px;
	font-weight: 400;
	width: 150px;
}

.item {
	font-weight: 600;
	margin-block: 5px;
}

.openTwo a {
	display: inline-block;
}
