.userFound {
	min-height: 800px;
}

.navigation {
	background-color: var(--light);
	border-radius: 4px;
	margin-bottom: 10px;
	transition: all 0.5s ease;
}

.userInfomation {
	padding: 20px 20px 0;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--input-border);
	transition: all 0.5s ease;
	flex-wrap: wrap;
	column-gap: 100px;
}

.recap {
	margin-bottom: 30px;
	transition: all 0.5s ease;
}

.recap p {
	margin-top: 15px;
	transition: all 0.5s ease;
}

.date {
	display: grid;
	grid-template-columns: 18px 1fr;
	column-gap: 10px;
	margin-bottom: 15px;
}

.date svg {
	color: var(--text-secondary);
	font-size: 18px;
}

.date p {
	margin-top: 10px;
}

@media screen and (max-width: 767px) {
	.userFound {
		min-height: 1000px;
	}
}
