.layout {
	height: 100dvh;
	overflow: hidden;
	position: relative;
}

.grid {
	display: grid;
	grid-template-columns: 50% 50%;
	height: 100%;
	overflow-y: scroll;
	overflow: overlay;
}

.content {
	max-height: 100%;
	background: rgba(255, 255, 255, 0.79);
	backdrop-filter: blur(10px);
}

.authBackground {
	position: absolute;
	background: linear-gradient(
			270deg,
			rgba(255, 255, 255, 0) 32.71%,
			rgba(255, 255, 255, 0.79) 100%
		),
		url('../../Assets/images/auth-bg.webp');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 65%;
	height: 100%;
	top: 0;
	right: 0;
	z-index: -1;
}

@media screen and (max-width: 991px) {
	.grid {
		grid-template-columns: 100% 0;
	}
	.content {
		padding: 60px 10px;
	}
	.authBackground {
		width: 100%;
	}
}
