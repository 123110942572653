.boxLogin {
	display: flex;
	align-items: center;
	padding: 60px 30px;
	min-height: 100%;
	overflow: auto;
}

.loginCard {
	text-align: center;
	width: 100%;
}

.loginCard p {
	color: var(--text-secondary-opacity);
	max-width: 410px;
	margin: 10px auto 30px;
}

.buttonSpid {
	font-size: 15px;
	background-color: #06c;
	max-width: 220px;
	margin-inline: auto;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.2s;
}

.buttonSpid:hover {
	background-color: #036;
}

.buttonSpid label {
	height: 100%;
	display: block;
	width: 100%;
	position: absolute;
	cursor: pointer !important;
	z-index: 3;
}

.buttonSpid form {
	cursor: pointer;
	position: relative;
}

.buttonSpid form input {
	background-color: transparent;
	outline: none;
	border: none;
	color: white;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.flex {
	display: flex;
	align-items: center;
}

.left {
	margin: 0 -0.4em 0 0;
	padding: 9px 0.8em 9px;
	border-right: rgba(255, 255, 255, 0.1) 0.1em solid;
}

.right {
	padding: 0.95em 1em 0.85em 1em;
	font-size: 1.15em;
	text-align: center;
	font-family: 'Titillium Web', HelveticaNeue, Helvetica Neue, Helvetica,
		Arial, Lucida Grande, sans-serif;
	font-weight: 600;
	line-height: 1em;
	text-align: center;
	cursor: pointer;
	color: white;
}

.icon {
	height: 29px;
	width: 29px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 50%;
}
