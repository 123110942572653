.chart {
	background-color: var(--light);
	padding: 20px;
	border-radius: 4px;
	margin-top: 25px;
	margin-bottom: 20px;
	transition: all 0.5s ease;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--text-secondary);
	margin-bottom: 40px;
	transition: all 0.5s ease;
}

.label {
	margin-bottom: 5px;
	font-weight: 500;
}

.title {
	color: var(--text-primary);
	transition: all 0.5s ease;
}

.info {
	font-weight: 500;
	font-size: var(--font-fluid-sm);
	color: var(--text-secondary);
	transition: all 0.5s ease;
}

.percentage {
	color: var(--success);
	font-weight: 700;
	font-size: var(--font-fluid-sm);
	transition: all 0.5s ease;
}
