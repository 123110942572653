.systemActive {
	padding: 28px 40px 30px;
	background-color: var(--light);
	margin-block: 25px 20px;
	border-radius: 4px;
	transition: all 0.5s ease;
}

.header {
	display: flex;
	align-items: center;
	column-gap: 20px;
	row-gap: 10px;
}

.header img {
	flex-shrink: 0;
}

@media screen and (max-width: 767px) {
	.systemActive {
		padding: 28px 20px 50px;
	}
	.header {
		flex-direction: column;
	}
	.info {
		text-align: center;
	}
}
