.platform {
	height: 100dvh;
	overflow: hidden;
	background-color: var(--light);
}
.platform main {
	height: calc(100% - var(--height-navbar));
	position: relative;
}
.renderedView {
	height: 100%;
	overflow-y: scroll;
	overflow: overlay;
	padding: 30px;
	transition: all 0.5s ease;
	margin-left: 0px;
	background-color: var(--body-bg);
}

@media screen and (min-width: 991px) {
	.renderedView {
		margin-left: 100px;
	}
}
@media screen and (max-width: 575px) {
	.renderedView {
		padding: 30px 10px;
	}
}
