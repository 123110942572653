@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,600,700,900');

:root {
	/* Transition */
	--transition-theme: 0.5s;

	--light: #ffffff;
	--dark: #000000;

	--primary: #6a64a9;
	--primary-opacity: #7f56d81a;
	--primary-hover: #6f4cbb;

	--text-primary: #2e2c34;
	--text-primary-opacity: #595959;
	--text-secondary: #757575;
	--text-secondary-opacity: #84818a;
	--text-placeholder: #b6b6b6;

	--text-primary-contrast: #2e2c34;

	--input-bg: #fbfbfb;
	--input-border: #e7e7e7;

	--error: #de4141;
	--success: #22d46b;

	--light-bg: #fafafa;
	--body-bg: #f9f9fb;
	--border-sidebar: #efefef;
	--alternative-bg: #6a64a9;

	--height-navbar: 85px;
	--width-sidebar: 248px;
	--width-sidebar-collapsed: 100px;

	--font-fluid-sm: clamp(0.8rem, 0.17vw + 0.76rem, 0.89rem);
	--font-fluid-flat: clamp(0.95rem, 0.2vw + 0.74rem, 0.89rem);
	--font-fluid-base: clamp(1rem, 0.34vw + 0.91rem, 1.19rem);
	--font-fluid-md: clamp(1.25rem, 0.61vw + 1.1rem, 1.58rem);
	--font-fluid-lg: clamp(1.56rem, 1vw + 1.31rem, 2.11rem);
	--font-fluid-xl: clamp(1.95rem, 1.56vw + 1.56rem, 2.81rem);
	--font-fluid-xxl: clamp(2.44rem, 2.38vw + 1.85rem, 3.75rem);
	--font-fluid-xxxl: clamp(3.05rem, 3.54vw + 2.17rem, 5rem);

	--variant-bg-1: #f5edfd;
	--variant-bg-2: #e6f9ef;
	--variant-bg-3: #faebec;
	--variant-bg-4: #fff5e2;
}

[data-theme='dark'] {
	--light: #2d2d2d;

	--text-primary: #ffffff;
	--text-primary-opacity: #b3b3b3;
	--text-primary-contrast: #aaaaaa;
	--text-secondary-opacity: #b7b4bd;

	--input-bg: #222222;
	--input-border: #777777;

	--light-bg: #323232;
	--body-bg: #222222;
	--border-sidebar: #efefef17;

	--variant-bg-1: #392c46;
	--variant-bg-2: #2a3831;
	--variant-bg-3: #483233;
	--variant-bg-4: #484031;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* scrollbar-width: thin;
	scrollbar-color: var(--primary-hover); */
}

html {
	scroll-behavior: smooth;
	background-color: var(--light);
}

::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	background: var(--primary-hover);
}

body {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: var(--font-fluid-flat);
	scrollbar-width: thin;
	scrollbar-color: var(--primary-hover) transparent; /* Only Firefox */
	color: var(--text-primary);
}

main {
	color: var(--text-primary);
}

h1 {
	font-size: var(--font-fluid-xxxl);
}

h2 {
	font-size: var(--font-fluid-xxl);
}

h3 {
	font-size: var(--font-fluid-xl);
}

h4 {
	font-size: var(--font-fluid-lg);
}

h5 {
	font-size: var(--font-fluid-md);
}

h6 {
	font-size: var(--font-fluid-base);
}

p {
	color: var(--text-secondary);
}

input {
	font-family: 'Montserrat', sans-serif;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
	padding: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	border-radius: 0.3rem;
	background-color: var(--primary);
	color: #fff;
	transition: all 0.5s ease;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
	background-color: var(--primary-hover);
}

.react-datepicker__day--in-selecting-range:not(
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--in-range
	),
.react-datepicker__month-text--in-selecting-range:not(
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--in-range
	),
.react-datepicker__quarter-text--in-selecting-range:not(
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--in-range
	),
.react-datepicker__year-text--in-selecting-range:not(
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--in-range
	) {
	background-color: rgba(106, 100, 169, 0.7);
}
