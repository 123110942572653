.information {
	background-color: var(--light);
	padding: 20px;
	border-radius: 4px;
	margin-top: 25px;
	margin-bottom: 20px;
	transition: all 0.5s ease;
}

.grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 17px;
	row-gap: 10px;
}

.box {
	padding: 20px;
	border-radius: 4px;
	transition: all 0.5s ease;
}

.title {
	font-weight: 600;
	font-size: var(--font-fluid-sm);
	margin-bottom: 15px;
	color: var(--text-primary-contrast);
	transition: all 0.5s ease;
}

.amount {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.percentage,
.percentageLoss {
	font-weight: 700;
	font-size: var(--font-fluid-sm);
	background-color: var(--light);
	border-radius: 70px;
	padding: 7px 12px;
	color: var(--success);
	transition: all 0.5s ease;
}

.percentageLoss {
	color: var(--error);
	transition: all 0.5s ease;
}

.box:nth-child(1) {
	background-color: var(--variant-bg-1);
	transition: all 0.5s ease;
}

.box:nth-child(2) {
	background-color: var(--variant-bg-2);
	transition: all 0.5s ease;
}

.box:nth-child(3) {
	background-color: var(--variant-bg-3);
	transition: all 0.5s ease;
}

.box:nth-child(4) {
	background-color: var(--variant-bg-4);
	transition: all 0.5s ease;
}

@media screen and (max-width: 1299px) {
	.grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 767px) {
	.grid {
		grid-template-columns: repeat(1, 1fr);
	}
}
