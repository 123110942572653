.vaccination {
	background-color: var(--light);
	border-radius: 4px;
	padding: 25px;
	transition: all 0.5s ease;
}

.vaccinationInfo {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 70px;
	border-bottom: 1px solid var(--input-border);
	transition: all 0.5s ease;
	padding-bottom: 20px;
}

.vaccinationInfo div {
	font-weight: 500;
	color: var(--text-secondary-opacity);
	transition: all 0.5s ease;
	display: flex;
	align-items: center;
	column-gap: 6px;
}

.status,
.doses,
.lastDoseData {
	font-weight: 600;
	font-size: var(--font-fluid-base);
	transition: all 0.5s ease;
}

.status {
	color: var(--success);
}

.doses {
	color: var(--primary);
}
