.container {
	animation: fade 0.5s ease;
}

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Button */
.button {
	background-color: var(--primary);
	color: white; /* Rimane sempre bianco */
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px;
	height: 44px;
	font-weight: 600;
	border-radius: 4px;
	min-width: 100px;
}

.button:hover {
	cursor: pointer;
	background-color: var(--primary-hover);
}

.buttonDisabled {
	opacity: 0.5;
}

.buttonDisabled:hover {
	background-color: var(--primary);
}

/* Input */

.inputLabel {
	font-weight: 600;
	font-size: 14px;
	line-height: 25px;
	letter-spacing: 0.2px;
	transition: all 0.5s ease;
	color: var(--text-primary);
}

.inputBase,
.inputError,
.inputSuccess {
	border: 1px solid var(--input-border);
	background-color: var(--input-bg);
	height: 44px;
	border-radius: 4px;
	transition: all 0.5s ease;
}

.inputBase input,
.inputError input,
.inputSuccess input {
	color: var(--text-primary);
	caret-color: var(--primary);
	font-size: var(--font-fluid-flat);
	transition: all 0.5s ease;
}
.inputBase input::placeholder,
.inputError input::placeholder,
.inputSuccess input::placeholder {
	transition: all 0.5s ease;
	color: var(--text-placeholder);
}

/* Select */
.selectClass {
	border: 1px solid var(--input-border);
	background-color: var(--input-bg);
	padding: 6px 10px;
	min-width: 150px;
	min-height: 44px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	font-size: var(--font-fluid-flat);
	color: var(--text-primary);
	transition: all 0.5s ease;
}

.selectPlaceholderClass {
	color: var(--text-placeholder);
	font-size: var(--font-fluid-flat);
}

.selectOpenedClass {
	border-width: 1px;
	border-style: solid;
	border-color: var(--input-border);
	background-color: var(--input-bg);
	width: calc(100% + 1.9px);
	left: -0.95px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	scrollbar-color: transparent transparent; /* Only Firefox */
}

.selectOpenedClass::-webkit-scrollbar {
	width: 0px; /* Chrome */
	background: transparent; /* Chrome */
}

.selectOptionClass {
	padding: 15px;
	color: var(--text-primary);
}

.selectOptionClass:hover {
	background-color: rgba(128, 128, 128, 0.121);
}

/* Modal  */
.modal {
	border-radius: 8px;
	max-width: 500px;
	background-color: var(--light);
	transition: all 0.4s ease;
}
.modalContent {
	padding: 20px 20px 30px;
}
.modalCloseIcon {
	color: var(--light);
	text-align: right;
}
