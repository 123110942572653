.system {
	padding: 28px 40px 50px;
	background-color: var(--light);
	margin-block: 25px 20px;
	border-radius: 4px;
	transition: all 0.5s ease;
}

.system h6 {
	margin-bottom: 30px;
}

.system h6:not(:first-child) {
	margin-block: 50px 30px;
}

.duoBox {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 80px;
	margin-bottom: 10px;
	row-gap: 10px;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--input-border);
	transition: all 0.5s ease;
}

.duoBox input[type='date'] {
	transition: all 0.5s ease;
}

.boxSelect {
	margin-bottom: 20px;
}

.label {
	font-weight: 600;
	font-size: 14px;
	line-height: 25px;
	letter-spacing: 0.2px;
	transition: all 0.5s ease;
	color: var(--text-primary);
}

.createButton {
	margin-top: 15px;
	max-width: 140px;
	margin-left: auto;
}

@media screen and (max-width: 991px) {
	.duoBox {
		column-gap: 40px;
	}
}

@media screen and (max-width: 767px) {
	.system {
		padding: 28px 20px 50px;
	}
	.duoBox {
		grid-template-columns: 1fr;
	}
}
