.draggable {
	overflow: scroll;
	overflow-y: hidden;
	padding-bottom: 22px;
}

.draggable::-webkit-scrollbar-track {
	scrollbar-color: var(--primary-hover) transparent;
}

.draggable::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0);
}

.draggable.active {
	cursor: grabbing;
	cursor: -webkit-grabbing;
}
