.notification {
	height: 40px;
	width: 40px;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	background-color: var(--light);
	border: 1px solid var(--input-border);
	border-radius: 50%;
	font-size: 25px;
	position: relative;
	z-index: 2;
	transition: all 0.5s ease;
}

.notification svg {
	color: var(--text-primary);
	flex-shrink: 0;
	transition: all 0.5s ease;
}

.notification:hover {
	cursor: pointer;
	filter: brightness(0.9);
	/* background-color: var(--light-bg); */
}

.newNotify {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(1px, -100%);
	border: 2px solid var(--light);
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: var(--error);
	transition: all 0.5s ease;
}
