.loader {
	width: 100%;
	height: 100%;
	background-color: var(--body-bg);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 6999;
	margin-left: 100px;
}

.loader img {
	width: 150px;
	animation: smooothpulse 1.5s infinite;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(calc(-50% - 50px), -50%);
}

/* animation logo loading */
@keyframes smooothpulse {
	0% {
		transform: scale(0.95) translate(calc(-50% - 50px), -50%);
		opacity: 0.7;
	}
	50% {
		transform: scale(1) translate(calc(-50% - 50px), -50%);
		opacity: 1;
	}
	100% {
		transform: scale(0.95) translate(calc(-50% - 50px), -50%);
		opacity: 0.7;
	}
}

@media screen and (max-width: 990px) {
	.loader {
		margin-left: 0;
	}
}
