.userNotFound {
	padding: 20px;
	border-radius: 4px;
	background-color: var(--light);
	margin-bottom: 20px;
	transition: all 0.5s ease;
}

.userNotFound p {
	margin-block: 8px 25px;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--input-border);
	transition: all 0.5s ease;
}

.addInfo {
	max-width: 225px;
	margin-inline: auto;
}
