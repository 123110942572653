.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.button {
	width: 100%;
	max-width: 170px;
}
