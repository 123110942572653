.response {
	text-align: center;
}

.response h5 {
	margin-block: 10px;
}

.response img {
	animation: appertoground 0.3s ease-in-out;
}

@keyframes appertoground {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.response p {
	margin-block: 10px 20px;
}

.close {
	margin-top: 30px;
}
