.navbar {
	padding: 0 20px;
	display: flex;
	column-gap: 16px;
	padding-top: 15px;
}

.tab,
.tabActive,
.tabDisabled {
	white-space: nowrap;
	padding: 10px 16px;
	font-weight: 500;
	color: var(--text-secondary-opacity);
	border-radius: 2px;
	border: 1px solid transparent;
	transition: all 0.3s;
}

.tab:hover,
.tabDisabled:hover {
	color: var(--primary);
	cursor: pointer;
	border: 1px solid var(--primary-opacity);
}

.tabDisabled:hover {
	cursor: not-allowed;
}

.tabActive {
	background-color: var(--primary-opacity);
	color: var(--primary);
}
