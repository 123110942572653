.loginProfileCard {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	background: rgba(255, 255, 255, 0.79);
	backdrop-filter: blur(10px);
	padding: 60px 10px;
	overflow: hidden;
	overflow-y: auto;
}

.chooses {
	text-align: center;
	width: 100%;
}

.position {
	min-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 720px;
	margin-inline: auto;
}

.boxTypes {
	margin-top: 105px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
}

.typeVaccinatore,
.typeCittadino {
	background: linear-gradient(
		180deg,
		#ffffff 0%,
		rgba(255, 255, 255, 0.6) 100%
	);
	filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.1));
	border-radius: 7px;
	padding: 10px 20px;
}

.sprite {
	transform: translateY(-70px);
	height: calc(265px - 70px);
	position: relative;
}

.shapeOne,
.shapeTwo {
	position: absolute;
	top: 0;
	left: 50%;
	opacity: 0;
	transform: translateX(-50%);
	transition: all var(--transition-theme);
}

.typeVaccinatore:hover .shapeOne,
.typeCittadino:hover .shapeTwo {
	opacity: 0.08;
	transform: translateX(calc(-50% - 18px));
	z-index: -1;
}

.typeVaccinatore:hover .shapeTwo,
.typeCittadino:hover .shapeOne {
	opacity: 0.02;
	transform: translateX(calc(-50% - 36px));
	z-index: -2;
}

.actions {
	margin-top: 20px;
	border-top: 1px solid rgba(0, 0, 0, 0.06);
	padding: 28px 15px;
	color: var(--primary);
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 6px;
	justify-content: flex-end;
}

.actions:hover {
	cursor: pointer;
	color: var(--primary-hover);
}

.actions svg {
	margin-top: 3px;
}

@media screen and (max-width: 575px) {
	.boxTypes {
		grid-template-columns: 1fr;
		row-gap: 70px;
	}
	.typeVaccinatore,
	.typeCittadino {
		max-width: 350px;
		width: 100%;
		margin-inline: auto;
	}
	.sprite {
		transform: translateY(-60px);
		height: calc(235px - 60px);
	}
}
